import * as React from "react"
import './index.css'
import Welcome from '../components/Welcome'
import Loading from '../components/Loading'
import { Suspense } from "react"
import {Helmet} from "react-helmet"

const IndexPage = ({ location }) => {

  return (
    <div>{/* <React.StrictMode> */}
    <Helmet>
        <title>Pablo Humanes: Estrategia, Diseño y Arquitecura</title>
        <script>{`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="//pablohumanes.es/matomo/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </script>
    </Helmet>
    {/* <Suspense fallback={<Loading />}>  */}
      <div className="App">

        {/* <ShowPosts /> */}
        <Welcome props={ location }/>
      </div>
    {/* </Suspense>  */}
    {/* </React.StrictMode> */}</div>
  )
}

export default IndexPage