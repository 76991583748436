import React, { useState } from 'react'
import './welcome.css'
import WelcomeReveal from "../components/WelcomeReveal"
import { useTransition, animated } from "react-spring"

const Welcome = ({ ...location }) => {

    const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);

    const transition = useTransition(isWelcomeVisible, {
      from: { x: 0, y: 40, opacity: 0},
      enter: { x: 0, y: 0, opacity: 1 },
      leave: { x: 0, y: 100, opacity: 0 },
      exitBeforeEnter: true,
    });

  return (
    <div>
      
      {/* <button className='front' onClick={() => {setIsWelcomeVisible(v => !v)}}>Mount welcome</button> */}
      <div>{transition((style, item) =>
        item ? <animated.div style={style}><WelcomeReveal props={ location } /></animated.div> : ''
        )}</div>
    </div>
  )
}

export default Welcome