import React, { useState } from 'react'
import './welcome.css'
import Fade from 'react-reveal/Fade'

const WelcomeReveal = ({...location}) => {

  const [plays, setPlays] = useState(false);
  
  return (
    <div className="welcome-container">
        <div className='welcome-box'>
          <div className='welcome-box-bg'>
            <Fade right distance={'3rem'} duration={1200} delay={200}>
            <div className="welcome-tag">I❤️‍🔥(cyber)space</div></Fade>
            <Fade right distance={'3rem'} duration={1400} delay={400}>
              <div className="super-display">Bienvenidx</div>
            </Fade>
            <div className="welcome-description-container">
            <Fade right distance={'2rem'} delay={800}>
              <div>Mi nombre es Pablo Humanes y soy arquitecto de experiencias:<wb /> físicas y virtuales.<wb /> Trabajo en marketing en el sector aeroespacial.</div>
            </Fade>
            <Fade right distance={'1rem'} delay={1000}>
              <div className="tiny-display margin-display">
                En esta web podrás ver algunos de mis trabajos,<wb /> consultar mi experiencia académica y profesional,<wb /> o ponerte en contacto conmigo.
              </div>
            </Fade>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WelcomeReveal